export const states = [
  { label: "Alabama (AL)", value: "AL" },
  { label: "Alaska (AK)", value: "AK" },
  { label: "Arizona (AZ)", value: "AZ" },
  { label: "Arkansas  (AR)", value: "AR" },
  { label: "California (CA)", value: "CA" },
  { label: "Colorado (CO)", value: "CO" },
  { label: "Connecticut (CT)", value: "CT" },
  { label: "Delaware (DE)", value: "DE" },
  { label: "District Of Columbia (DC)", value: "DC" },
  { label: "Florida (FL)", value: "FL" },
  { label: "Georgia (GA)", value: "GA" },
  { label: "Hawaii (HI)", value: "HI" },
  { label: "Idaho (ID)", value: "ID" },
  { label: "Illinois (IL)", value: "IL" },
  { label: "Indiana (IN)", value: "IN" },
  { label: "Iowa (IA)", value: "IA" },
  { label: "Kansas (KS)", value: "KS" },
  { label: "Kentucky (KY)", value: "KY" },
  { label: "Louisiana (LA)", value: "LA" },
  { label: "Maine (ME)", value: "ME" },
  { label: "Maryland (MD)", value: "MD" },
  { label: "Massachusetts (MA)", value: "MA" },
  { label: "Michigan (MI)", value: "MI" },
  { label: "Minnesota (MN)", value: "MN" },
  { label: "Mississippi (MS)", value: "MS" },
  { label: "Missouri (MO)", value: "MO" },
  { label: "Montana (MT)", value: "MT" },
  { label: "Nebraska (NE)", value: "NE" },
  { label: "Nevada (NV)", value: "NV" },
  { label: "New Hampshire (NH)", value: "NH" },
  { label: "New Jersey (NJ)", value: "NJ" },
  { label: "New Mexico (NM)", value: "NM" },
  { label: "New York (NY)", value: "NY" },
  { label: "North Carolina (NC)", value: "NC" },
  { label: "North Dakota (ND)", value: "ND" },
  { label: "Ohio (OH)", value: "OH" },
  { label: "Oklahoma (OK)", value: "OK" },
  { label: "Oregon (OR)", value: "OR" },
  { label: "Pennsylvania (PA)", value: "PA" },
  { label: "Rhode Island (RI)", value: "RI" },
  { label: "South Carolina  (SC)", value: "SC" },
  { label: "South Dakota (SD)", value: "SD" },
  { label: "Tennessee (TN)", value: "TN" },
  { label: "Texas (TX)", value: "TX" },
  { label: "Utah (UT)", value: "UT" },
  { label: "Vermont (VT)", value: "VT" },
  { label: "Virginia (VA)", value: "VA" },
  { label: "Washington  (WA)", value: "WA" },
  { label: "West Virginia (WV)", value: "WV" },
  { label: "Wisconsin (WI)", value: "WI" },
  { label: "Wyoming (WY)", value: "WY" },
];

export const countries = [
  { value: "United States", label: "United States" },
  { value: "Canada", label: "Canada" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Singapore", label: "Singapore" },
];

const canadianProvinces = [
  { label: "Alberta (AB)", value: "AB" },
  { label: "British Columbia (BC)", value: "BC" },
  { label: "Manitoba (MB)", value: "MB" },
  { label: "New Brunswick (NB)", value: "NB" },
  { label: "Newfoundland (NF)", value: "NF" },
  { label: "Northwest Territories (NT)", value: "NT" },
  { label: "Nova Scotia (NS)", value: "NS" },
  { label: "Nunavut (NU)", value: "NU" },
  { label: "Ontario (ON)", value: "ON" },
  { label: "Prince Edward Island (PE)", value: "PE" },
  { label: "Quebec (PQ)", value: "PQ" },
  { label: "Saskatchewan (SK)", value: "SK" },
  { label: "Yukon (YT)", value: "YT" },
];

const unitedKingdomProvinces = [
  { label: "Aberdeen City (ABE)", value: "ABE" },
  { label: "Aberdeenshire (ABD)", value: "ABD" },
  { label: "Angus (ANS)", value: "ANS" },
  { label: "Antrim and Newtownabbey (ANN)", value: "ANN" },
  { label: "Ards and North Down (GAND)", value: "GAND" },
  { label: "Argyll and Bute (AGB)", value: "AGB" },
  { label: "Armagh City (ABC)", value: "ABC" },
  { label: "Barking and Dagenham (BDG)", value: "BDG" },
  { label: "Barnet (BNE)", value: "BNE" },
  { label: "Barnsley (BNS)", value: "BNS" },
  { label: "Bath and North East Somerset (BAS)", value: "BAS" },
  { label: "Bedford (BDF)", value: "BDF" },
  { label: "Belfast (BFS)", value: "BFS" },
  { label: "Bexley (BEX)", value: "BEX" },
  { label: "Birmingham (BIR)", value: "BIR" },
  { label: "Blackburn with Darwen (BBD)", value: "BBD" },
  { label: "Blackpool (BPL)", value: "BPL" },
  { label: "Blaenau Gwent (BGW)", value: "BGW" },
  { label: "Bolton (BOL)", value: "BOL" },
  { label: "Bournemouth (BMH)", value: "BMH" },
  { label: "Bracknell Forest (BRC)", value: "BRC" },
  { label: "Bradford (BRD)", value: "BRD" },
  { label: "Brent (BEN)", value: "BEN" },
  { label: "Bridgend  (BGE)", value: "BGE" },
  { label: "Brighton and Hove (BNH)", value: "BNH" },
  { label: "Bristol, City of (BST)", value: "BST" },
  { label: "Bromley (BRY)", value: "BRY" },
  { label: "Buckinghamshire (BKM)", value: "BKM" },
  { label: "Bury (BUR)", value: "BUR" },
  { label: "Caerphilly  (CAY)", value: "CAY" },
  { label: "Calderdale (CLD)", value: "CLD" },
  { label: "Cambridgeshire (CAM)", value: "CAM" },
  { label: "Camden (CMD)", value: "CMD" },
  { label: "Cardiff (CRF)", value: "CRF" },
  { label: "Carmarthenshire  (CMN)", value: "CMN" },
  { label: "Causeway Coast and Glens (CCG)", value: "CCG" },
  { label: "Central Bedfordshire (CBF)", value: "CBF" },
  { label: "Ceredigion (CGN)", value: "CGN" },
  { label: "Cheshire East (CHE)", value: "CHE" },
  { label: "Cheshire West and Chester (CHW)", value: "CHW" },
  { label: "Clackmannanshire (CLK)", value: "CLK" },
  { label: "Conwy (CWY)", value: "CWY" },
  { label: "Cornwall (CON)", value: "CON" },
  { label: "Coventry (COV)", value: "COV" },
  { label: "Croydon (CRY)", value: "CRY" },
  { label: "Cumbria (CMA)", value: "CMA" },
  { label: "Darlington (DAL)", value: "DAL" },
  { label: "Denbighshire (DEN)", value: "DEN" },
  { label: "Derby (DER)", value: "DER" },
  { label: "Derbyshire (DBY)", value: "DBY" },
  { label: "Derry City and Strabane (DRS)", value: "DRS" },
  { label: "Devon (DEV)", value: "DEV" },
  { label: "Doncaster (DNC)", value: "DNC" },
  { label: "Dorset (DOR)", value: "DOR" },
  { label: "Dudley (DUD)", value: "DUD" },
  { label: "Dumfries and Galloway (DGY)", value: "DGY" },
  { label: "Dundee City (DND)", value: "DND" },
  { label: "Durham County (DUR)", value: "DUR" },
  { label: "Ealing (EAL)", value: "EAL" },
  { label: "East Ayrshire (EAY)", value: "EAY" },
  { label: "East Dunbartonshire (EDU)", value: "EDU" },
  { label: "East Lothian (ELN)", value: "ELN" },
  { label: "East Renfrewshire (ERW)", value: "ERW" },
  { label: "East Riding of Yorkshire (ERY)", value: "ERY" },
  { label: "East Sussex (ESX)", value: "ESX" },
  { label: "Edinburgh, City of (EDH)", value: "EDH" },
  { label: "Eilean Siar (ELS)", value: "ELS" },
  { label: "Enfield (ENF)", value: "ENF" },
  { label: "Essex (ESS)", value: "ESS" },
  { label: "Falkirk (FAL)", value: "FAL" },
  { label: "Fermanagh and Omagh (FMO)", value: "FMO" },
  { label: "Fife (FIF)", value: "FIF" },
  { label: "Flintshire  (FLN)", value: "FLN" },
  { label: "Gateshead (GAT)", value: "GAT" },
  { label: "Glasgow City (GLG)", value: "GLG" },
  { label: "Gloucestershire (GLS)", value: "GLS" },
  { label: "Greenwich (GRE)", value: "GRE" },
  { label: "Gwynedd (GWN)", value: "GWN" },
  { label: "Hackney (HCK)", value: "HCK" },
  { label: "Halton (HAL)", value: "HAL" },
  { label: "Hammersmith and Fulham (HMF)", value: "HMF" },
  { label: "Hampshire (HAM)", value: "HAM" },
  { label: "Haringey (HRY)", value: "HRY" },
  { label: "Harrow (HRW)", value: "HRW" },
  { label: "Hartlepool (HPL)", value: "HPL" },
  { label: "Havering (HAV)", value: "HAV" },
  { label: "Herefordshire (HEF)", value: "HEF" },
  { label: "Hertfordshire (HRT)", value: "HRT" },
  { label: "Highland (HLD)", value: "HLD" },
  { label: "Hillingdon (HIL)", value: "HIL" },
  { label: "Hounslow (HNS)", value: "HNS" },
  { label: "Inverclyde (IVC)", value: "IVC" },
  { label: "Isle of Anglesey  (AGY)", value: "AGY" },
  { label: "Isle of Wight (IOW)", value: "IOW" },
  { label: "Isles of Scilly (IOS)", value: "IOS" },
  { label: "Islington (ISL)", value: "ISL" },
  { label: "Kensington and Chelsea (KEC)", value: "KEC" },
  { label: "Kent (KEN)", value: "KEN" },
  { label: "Kingston upon Hull (KHL)", value: "KHL" },
  { label: "Kingston upon Thames (KTT)", value: "KTT" },
  { label: "Kirklees (KIR)", value: "KIR" },
  { label: "Knowsley (KWL)", value: "KWL" },
  { label: "Lambeth (LBH)", value: "LBH" },
  { label: "Lancashire (LAN)", value: "LAN" },
  { label: "Leeds (LDS)", value: "LDS" },
  { label: "Leicester (LCE)", value: "LCE" },
  { label: "Leicestershire (LEC)", value: "LEC" },
  { label: "Lewisham (LEW)", value: "LEW" },
  { label: "Lincolnshire (LIN)", value: "LIN" },
  { label: "Lisburn and Castlereagh (LBC)", value: "LBC" },
  { label: "Liverpool (LIV)", value: "LIV" },
  { label: "London, City of (LND)", value: "LND" },
  { label: "Luton (LUT)", value: "LUT" },
  { label: "Manchester (MAN)", value: "MAN" },
  { label: "Medway (MDW)", value: "MDW" },
  { label: "Merthyr Tydfil  (MTY)", value: "MTY" },
  { label: "Merton (MRT)", value: "MRT" },
  { label: "Mid Ulster (MUL)", value: "MUL" },
  { label: "Mid and East Antrim (MEA)", value: "MEA" },
  { label: "Middlesbrough (MDB)", value: "MDB" },
  { label: "Midlothian (MLN)", value: "MLN" },
  { label: "Milton Keynes (MIK)", value: "MIK" },
  { label: "Monmouthshire  (MON)", value: "MON" },
  { label: "Moray (MRY)", value: "MRY" },
  { label: "Neath Port Talbot (NTL)", value: "NTL" },
  { label: "Newcastle upon Tyne (NET)", value: "NET" },
  { label: "Newham (NWM)", value: "NWM" },
  { label: "Newport  (NWP)", value: "NWP" },
  { label: "Newry, Mourne and Down (NMD)", value: "NMD" },
  { label: "Norfolk (NFK)", value: "NFK" },
  { label: "North Ayrshire (NAY)", value: "NAY" },
  { label: "North East Lincolnshire (NEL)", value: "NEL" },
  { label: "North Lanarkshire (NLK)", value: "NLK" },
  { label: "North Lincolnshire (NLN)", value: "NLN" },
  { label: "North Somerset (NSM)", value: "NSM" },
  { label: "North Tyneside (NTY)", value: "NTY" },
  { label: "North Yorkshire (NYK)", value: "NYK" },
  { label: "Northamptonshire (NTH)", value: "NTH" },
  { label: "Northumberland (NBL)", value: "NBL" },
  { label: "Nottingham (NGM)", value: "NGM" },
  { label: "Nottinghamshire (NTT)", value: "NTT" },
  { label: "Oldham (OLD)", value: "OLD" },
  { label: "Orkney Islands (ORK)", value: "ORK" },
  { label: "Oxfordshire (OXF)", value: "OXF" },
  { label: "Pembrokeshire (PEM)", value: "PEM" },
  { label: "Perth and Kinross (PKN)", value: "PKN" },
  { label: "Peterborough (PTE)", value: "PTE" },
  { label: "Plymouth (PLY)", value: "PLY" },
  { label: "Poole (POL)", value: "POL" },
  { label: "Portsmouth (POR)", value: "POR" },
  { label: "Powys (POW)", value: "POW" },
  { label: "Reading (RDG)", value: "RDG" },
  { label: "Redbridge (RDB)", value: "RDB" },
  { label: "Redcar and Cleveland (RCC)", value: "RCC" },
  { label: "Renfrewshire (RFW)", value: "RFW" },
  { label: "Rhondda (RCT)", value: "RCT" },
  { label: "Richmond upon Thames (RIC)", value: "RIC" },
  { label: "Rochdale (RCH)", value: "RCH" },
  { label: "Rotherham (ROT)", value: "ROT" },
  { label: "Rutland (RUT)", value: "RUT" },
  { label: "Salford (SLF)", value: "SLF" },
  { label: "Sandwell (SAW)", value: "SAW" },
  { label: "Scottish Borders (SCB)", value: "SCB" },
  { label: "Sefton (SFT)", value: "SFT" },
  { label: "Sheffield (SHF)", value: "SHF" },
  { label: "Shetland Islands (ZET)", value: "ZET" },
  { label: "Shropshire (SHR)", value: "SHR" },
  { label: "Slough (SLG)", value: "SLG" },
  { label: "Solihull (SOL)", value: "SOL" },
  { label: "Somerset (SOM)", value: "SOM" },
  { label: "South Ayrshire (SAY)", value: "SAY" },
  { label: "South Gloucestershire (SGC)", value: "SGC" },
  { label: "South Lanarkshire (SLK)", value: "SLK" },
  { label: "South Tyneside (STY)", value: "STY" },
  { label: "Southampton (STH)", value: "STH" },
  { label: "Southend-on-Sea (SOS)", value: "SOS" },
  { label: "Southwark (SWK)", value: "SWK" },
  { label: "St. Helens (SHN)", value: "SHN" },
  { label: "Staffordshire (STS)", value: "STS" },
  { label: "Stirling (STG)", value: "STG" },
  { label: "Stockport (SKP)", value: "SKP" },
  { label: "Stockton-on-Tees (STT)", value: "STT" },
  { label: "Stoke-on-Trent (STE)", value: "STE" },
  { label: "Suffolk (SFK)", value: "SFK" },
  { label: "Sunderland (SND)", value: "SND" },
  { label: "Surrey (SRY)", value: "SRY" },
  { label: "Sutton (STN)", value: "STN" },
  { label: "Swansea (SWA)", value: "SWA" },
  { label: "Swindon (SWD)", value: "SWD" },
  { label: "Tameside (TAM)", value: "TAM" },
  { label: "Telford and Wrekin (TFW)", value: "TFW" },
  { label: "Thurrock (THR)", value: "THR" },
  { label: "Torbay (TOB)", value: "TOB" },
  { label: "Torfaen  (TOF)", value: "TOF" },
  { label: "Tower Hamlets (TWH)", value: "TWH" },
  { label: "Trafford (TRF)", value: "TRF" },
  { label: "Vale of Glamorgan (VGL)", value: "VGL" },
  { label: "Wakefield (WKF)", value: "WKF" },
  { label: "Walsall (WLL)", value: "WLL" },
  { label: "Waltham Forest (WFT)", value: "WFT" },
  { label: "Wandsworth (WND)", value: "WND" },
  { label: "Warrington (WRT)", value: "WRT" },
  { label: "Warwickshire (WAR)", value: "WAR" },
  { label: "West Berkshire (WBK)", value: "WBK" },
  { label: "West Dunbartonshire (WDU)", value: "WDU" },
  { label: "West Lothian (WLN)", value: "WLN" },
  { label: "West Sussex (WSX)", value: "WSX" },
  { label: "Westminster (WSM)", value: "WSM" },
  { label: "Wigan (WGN)", value: "WGN" },
  { label: "Wiltshire (WIL)", value: "WIL" },
  { label: "Windsor and Maidenhead (WNM)", value: "WNM" },
  { label: "Wirral (WRL)", value: "WRL" },
  { label: "Wokingham (WOK)", value: "WOK" },
  { label: "Wolverhampton (WLV)", value: "WLV" },
  { label: "Worcestershire (WOR)", value: "WOR" },
  { label: "Wrexham  (WRX)", value: "WRX" },
  { label: "York (YOR)", value: "YOR" },
];

const netherlandsProvinces = [
  { label: "Drenthe (DR)", value: "DR" },
  { label: "Flevoland (FL)", value: "FL" },
  { label: "Fryslân (FR)", value: "FR" },
  { label: "Gelderland (GE)", value: "GE" },
  { label: "Groningen (GR)", value: "GR" },
  { label: "Limburg (LI)", value: "LI" },
  { label: "Noord-Brabant (NB)", value: "NB" },
  { label: "Noord-Holland (NH)", value: "NH" },
  { label: "Overijssel (OV)", value: "OV" },
  { label: "Utrecht (UT)", value: "UT" },
  { label: "Zeeland (ZE)", value: "ZE" },
  { label: "Zuid-Holland (ZH)", value: "ZH" },
];

const singaporeProvinces = [
  { label: "Central Singapore (01)", value: "01" },
  { label: "North East (02)", value: "02" },
  { label: "North West (03)", value: "03" },
  { label: "South East (04)", value: "04" },
  { label: "South West (05)", value: "05" },
];

const regions = new Map([
  ["United States", states],
  ["Canada", canadianProvinces],
  ["Singapore", singaporeProvinces],
  ["United Kingdom", unitedKingdomProvinces],
  ["Netherlands", netherlandsProvinces],
]);

export type Countries =
  | "United States"
  | "Canada"
  | "Singapore"
  | "United Kingdom"
  | "Netherlands";

export const getRegionOptions = (country: string) => {
  return regions.get(country) || [];
};
