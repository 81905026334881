import { UseQueryResult } from "@tanstack/react-query";

import { createAxiosQuery } from "@/react/hooks/react-query";
import { instance } from "@/react/hooks/api";

interface FeatureFlagParams {
  flag: string;
  groupId?: number | string | null;
  organizationId?: number | string | null;
}

interface FeatureFlagResponse {
  enabled: boolean;
}

const DEFAULT_ENABLED_RESPONSE: FeatureFlagResponse = { enabled: false };
type FeatureFlagEnabledResponse = UseQueryResult<FeatureFlagResponse> & {
  data: FeatureFlagResponse;
};

/** Use a feature flag
 * @params {FeatureFlagParams} params - The feature flag to check. Can be scoped to a group or organization.
 * @params {React Query Option} options - Options to pass to the query.
 */
export const useFeatureFlagEnabled = (
  params: FeatureFlagParams,
  options?: Parameters<typeof useFeatureFlagEnabledQuery>[1]
) => {
  // this is a wrapper to force the return type to always be FeatureFlagResponse
  const response = useFeatureFlagEnabledQuery(params, options);
  return response as FeatureFlagEnabledResponse;
};

/** Logic for the feature flag query. */
const useFeatureFlagEnabledQuery = createAxiosQuery<
  FeatureFlagParams,
  FeatureFlagResponse
>(
  "feature-flag",
  async ({ flag, groupId, organizationId }) => {
    try {
      const { data } = await instance().get<FeatureFlagResponse>(
        `/feature-flags/${flag}`,
        { params: { group_id: groupId, organization_id: organizationId } }
      );
      return data;
    } catch (e) {
      return DEFAULT_ENABLED_RESPONSE;
    }
  },
  {
    placeholderData: DEFAULT_ENABLED_RESPONSE,
  }
);

export const useIsOrgFeatureFlagEnabled = createAxiosQuery(
  "org-level-feature-flag",
  async ({ flag }: FeatureFlagParams) => {
    try {
      return (
        await instance().get<FeatureFlagResponse>(`/feature-flags/${flag}`)
      ).data;
    } catch (e) {
      return DEFAULT_ENABLED_RESPONSE;
    }
  },
  {
    placeholderData: DEFAULT_ENABLED_RESPONSE,
  }
);
