import { range, sortBy, startCase } from "lodash-es";
import { format as formatTz } from "date-fns-tz";

export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;

// This doesn't work for every state, but many states use a single timezone
// https://en.wikipedia.org/wiki/List_of_time_offsets_by_U.S._state_and_territory
// https://worldpopulationreview.com/state-rankings/central-time-zone-states
export function usStatesToTimezone(state: string): string {
  if (!state) {
    return "";
  }

  switch (state) {
    // Central
    case "AL":
    case "AR":
    case "IL":
    case "IA":
    case "LA":
    case "MN":
    case "MS":
    case "MO":
    case "OK":
      return "America/Chicago";

    // Mountain
    case "CO":
    case "MT":
    case "NM":
    case "UT":
      return "America/Denver";

    // Phoenix
    case "AZ":
      return "America/Phoenix";

    case "CT":
    case "GA":
    case "MA":
    case "RI":
    case "ME":
    case "MD":
    case "NH":
    case "NY":
    case "NC":
    case "NJ":
    case "OH":
    case "SC":
    case "VT":
    case "WV":
      return "America/New_York";

    // Pacific
    case "CA":
    case "WA":
      return "America/Los_Angeles";

    default:
      return "";
  }
}

export const timezones = sortBy(
  [
    // List of supported timezone names from the IANA timezone database.
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Edmonton",
    "America/Halifax",
    "America/Iqaluit",
    "America/Los_Angeles",
    "America/New_York",
    "America/Phoenix",
    "America/Regina",
    "America/St_Johns",
    "America/Swift_Current",
    "America/Thunder_Bay",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yellowknife",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Helsinki",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Paris",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Pacific/Honolulu",
  ],
  // Sort by GMT offset as a number (-1000, -800, -700, etc.). This will produce
  // a list that is, in general, sorted from west to east starting from the
  // international date line.
  (tz) => Number(formatTz(new Date(), "xx", { timeZone: tz }))
);

export const timezoneOptions = timezones.map((tz) => {
  // e.g. GMT-06:00
  const gmtOffset = formatTz(new Date(), "OOOO", { timeZone: tz });

  // e.g. MST, MDT, PST, PDT. Sometimes produces GMT offsets instead, paricularly
  // for non-North American timezones -- this may be due to system locale settings.
  const tzAbbr = formatTz(new Date(), "zzz", { timeZone: tz });

  // Replace underscores in the timezone name, but only for the label
  const zoneName = tz.replaceAll("_", " ");

  return {
    label: `(${gmtOffset}) ${zoneName} (${tzAbbr})`,
    value: tz,
  };
});

export enum TemperatureScale {
  fahrenheit = "farenheit",
  celsius = "celsius",
}

export const statusOptions = [
  { label: "Model", value: "Model" },
  { label: "Vacant", value: "Vacant" },
  { label: "Down", value: "Down" },
  { label: "Occupied", value: "Occupied" },
  { label: "Common Area", value: "Common Area" },
];

export const CELSIUS = "celsius";
export const FAHRENHEIT = "fahrenheit";

export const temperatureScales = [FAHRENHEIT, CELSIUS];

export const temperatureScaleOptions = temperatureScales.map((scale) => ({
  value: scale,
  label: startCase(scale),
}));

export const getYears = () =>
  range(new Date().getFullYear() + 2, 1900, -1).map((year) => ({
    label: String(year),
    value: String(year),
  }));

// https://stackoverflow.com/a/40197728/8786413
export const convertTime12to24 = (time12h?: string) => {
  if (!time12h) {
    return "";
  }

  const [time, modifier] = time12h.split(" ");

  // eslint-disable-next-line prefer-const -- We need to use `let` for `hours` and we can't use `let` on one and `const` on the other.
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = `${parseInt(hours, 10) + 12}`;
  }

  return `${hours}:${minutes}`;
};

export const times = [
  { label: "12:00 AM", value: "00:00:00" },
  { label: "12:30 AM", value: "00:30:00" },
  { label: "1:00 AM", value: "01:00:00" },
  { label: "1:30 AM", value: "01:30:00" },
  { label: "2:00 AM", value: "02:00:00" },
  { label: "2:30 AM", value: "02:30:00" },
  { label: "3:00 AM", value: "03:00:00" },
  { label: "3:30 AM", value: "03:30:00" },
  { label: "4:00 AM", value: "04:00:00" },
  { label: "4:30 AM", value: "04:30:00" },
  { label: "5:00 AM", value: "05:00:00" },
  { label: "5:30 AM", value: "05:30:00" },
  { label: "6:00 AM", value: "06:00:00" },
  { label: "6:30 AM", value: "06:30:00" },
  { label: "7:00 AM", value: "07:00:00" },
  { label: "7:30 AM", value: "07:30:00" },
  { label: "8:00 AM", value: "08:00:00" },
  { label: "8:30 AM", value: "08:30:00" },
  { label: "9:00 AM", value: "09:00:00" },
  { label: "9:30 AM", value: "09:30:00" },
  { label: "10:00 AM", value: "10:00:00" },
  { label: "10:30 AM", value: "10:30:00" },
  { label: "11:00 AM", value: "11:00:00" },
  { label: "11:30 AM", value: "11:30:00" },
  { label: "12:00 PM", value: "12:00:00" },
  { label: "12:30 PM", value: "12:30:00" },
  { label: "1:00 PM", value: "13:00:00" },
  { label: "1:30 PM", value: "13:30:00" },
  { label: "2:00 PM", value: "14:00:00" },
  { label: "2:30 PM", value: "14:30:00" },
  { label: "3:00 PM", value: "15:00:00" },
  { label: "3:30 PM", value: "15:30:00" },
  { label: "4:00 PM", value: "16:00:00" },
  { label: "4:30 PM", value: "16:30:00" },
  { label: "5:00 PM", value: "17:00:00" },
  { label: "5:30 PM", value: "17:30:00" },
  { label: "6:00 PM", value: "18:00:00" },
  { label: "6:30 PM", value: "18:30:00" },
  { label: "7:00 PM", value: "19:00:00" },
  { label: "7:30 PM", value: "19:30:00" },
  { label: "8:00 PM", value: "20:00:00" },
  { label: "8:30 PM", value: "20:30:00" },
  { label: "9:00 PM", value: "21:00:00" },
  { label: "9:30 PM", value: "21:30:00" },
  { label: "10:00 PM", value: "22:00:00" },
  { label: "10:30 PM", value: "22:30:00" },
  { label: "11:00 PM", value: "23:00:00" },
  { label: "11:30 PM", value: "23:30:00" },
];
