import { AppState } from "react-native";

// Every X milliseconds, make a request inside an iframe that gets a
// fresh access token and save it in memory.
const HEARTBEAT_INTERVAL = 30000;

AppState.addEventListener("change", (state) => {
  if (state === "active" && window.websocketAccessToken) {
    try {
      const jwtExp: number =
        JSON.parse(atob(window.websocketAccessToken.split(".")[1])).exp * 1000;

      // If the token is expired, reload the page. Maybe they get a new token but most
      // likely they will have to log in again.
      if (jwtExp - Date.now() <= 0) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }
});

let heartbeatTimer: ReturnType<typeof setTimeout>;
let idleSessionTimer: ReturnType<typeof setTimeout>;

export function resetIdleSessionTimeout() {
  if (!window.idleSessionTimeout) {
    return;
  }

  clearTimeout(idleSessionTimer);

  idleSessionTimer = setTimeout(() => {
    clearTimeout(heartbeatTimer);
    window.websocketAccessToken = null;

    const form = document.createElement("form");
    form.action = `/sessions?redirect_to=${window.location.pathname}`;
    form.method = "POST";

    const csrf = document.createElement("input");
    csrf.type = "hidden";
    csrf.name = "_csrf_token";
    csrf.value = window.csrfToken;
    form.appendChild(csrf);

    const method = document.createElement("input");
    method.type = "hidden";
    method.name = "_method";
    method.value = "delete";
    form.appendChild(method);

    document.body.appendChild(form);
    form.submit();
  }, window.idleSessionTimeout * 60 * 1000);
}

// implicitly refresh access token as long as the session is valid
// (called the "implicit flow" of authentication)
// https://auth0.com/docs/api-auth/tutorials/silent-authentication#renew-expired-tokens
export function authenticationHeartbeat() {
  if (!window.websocketAccessToken) {
    return;
  }

  let heartbeatInterval = HEARTBEAT_INTERVAL;

  try {
    const jwtExp: number = JSON.parse(
      atob(window.websocketAccessToken.split(".")[1])
    ).exp;

    const millisecondsUntilExpiration =
      new Date(jwtExp * 1000).getTime() - new Date().getTime();

    if (millisecondsUntilExpiration <= 0) {
      // If the token is expired, reload the page. Maybe they get a new token but most
      // likely they will have to log in again.
      window.location.reload();
      return;
    }

    // Subtract 10s to account for potential network latency. This gives us some wiggle
    // room to make sure the token gets refreshed before it actually expires.
    heartbeatInterval = millisecondsUntilExpiration - 10000;

    if (heartbeatInterval <= 0) {
      heartbeatInterval = 1;
    }
  } catch {
    console.warn("Unable to calculate heartbeat");
  }

  heartbeatTimer = setTimeout(function () {
    // 1. Create an iframe and load a webpage with the user's session cookie and append iframe to document.body
    const el = document.createElement("iframe");
    el.style.display = "none";
    el.src = `/heartbeat`; // set src to new url

    el.onload = function () {
      try {
        if (el.contentWindow?.websocketAccessToken) {
          // 2. When the iframe is loaded, grab the access token from that and re-apply to this window's access token
          window.websocketAccessToken = el.contentWindow?.websocketAccessToken; // reference to iframe's window

          // 3. Destroy iframe
          el.parentNode?.removeChild(el);

          // 4. Repeat. We need recalculate when to trigger the next heartbeat.
          authenticationHeartbeat();
        } else {
          // The heartbeat is scheduled to occur about 10s before the token
          // expires. If we experience an error during that brief period of
          // time then it's unlikely that we'll be able to recover so we might
          // as well treat it as if it were already expired.
          window.location.reload();
        }
      } catch (e) {
        console.error(e);
        window.location.reload();
      }
    };

    document.body.appendChild(el);
  }, heartbeatInterval);
}

resetIdleSessionTimeout();
